<template>
    <div>
        <slot name="text"></slot>
        <v-form ref="form" v-model="valid" :disabled="invalidInvitation">
            <v-text-field v-model="email" required :rules="emailRules" outlined dense placeholder="Your e-mail address"></v-text-field>
            <v-text-field v-model="emailValidate" required dense outlined :rules="emailRules" placeholder="Confirm your e-mail"></v-text-field>
            <div id="g-recaptcha" class="d-flex justify-center mb-5"></div>
            <v-btn class="primary" elevation="0" block :loading="loading || validating" :disabled="!valid || recaptchaError" @click="validateEmail()">
                Request sign up e-mail
            </v-btn>
        </v-form>
        <invitation-errors-dialog
            :showDialog="showInvitationErrorsDialog"
            :errorType="validationErrorType"
            :invalidEmails="invalidEmailsList"
            @proceedWithTheInvitation="trySignUp($event.value)"
            @validateEmailsAgain="validateEmail($event.value)"
            :isSignUpDialog="true" />
    </div>
</template>

<script>
import { emailValidation } from '@/mixins/validateEmail'
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog.vue')

export default {
    name: 'EmailSignupForm',
    mixins: [emailValidation],
    components: { InvitationErrorsDialog },
    props: {
        loading: Boolean,
        emailDomainWhitelist: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            email: '',
            emailValidate: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                v => v === this.email || "E-mails don't match",
                v =>
                    !this.emailDomainWhitelist.length ||
                    this.emailDomainWhitelist.some(wl => this.email.endsWith(wl)) ||
                    'Email must end with one of: @' + this.emailDomainWhitelist.join(', @')
            ],
            valid: false,
            validating: false,
            invalidInvitation: false,
            recaptchaError: true
        }
    },
    created() {},
    mounted() {
        const recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit')
        document.head.appendChild(recaptchaScript)
        window.recaptchaOnloadCallback = () => {
            window.grecaptcha.render('g-recaptcha', {
                sitekey: '6LfHPpIbAAAAAKOYkCh6CHyunO5qiCf73Gx5Ywbj',
                callback: this.verifyCaptcha
            })
        }
    },
    methods: {
        verifyCaptcha(response) {
            this.recaptchaError = response === ''
        },
        validateEmail() {
            this.validating = true
            this.validateEmailsWithPublicMailgunApi(this.email).finally(async () => {
                if (!this.emailsValidationError && !this.foundInvalidEmails && this.emailValidatedWithPublicAPI) {
                    localStorage.setItem('nvLastEmail', this.email)
                    const recaptchaToken = window.grecaptcha.getResponse()
                    this.validating = false
                    this.$emit('verifiedEmail', { email: this.email, recaptchaToken })
                }
            })
        }
    },
    computed: {
        validationErrorType() {
            if (this.foundInvalidEmails) {
                return this.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.backendError || this.emailsValidationError) {
                return this.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        showInvitationErrorsDialog() {
            if (this.foundInvalidEmails || this.backendError || this.emailsValidationError) {
                return true
            }
            return false
        }
    },
    watch: {}
}
</script>
